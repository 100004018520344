<template>
  <TemplateAccount>
    <v-form
      @submit.prevent="handleRecoverPassword"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <h4 class="form-reset-password--content_subtitle">
        Informe uma nova senha
      </h4>

      <div class="form-reset-password--content_fields">
        <BasePassword
          id="password"
          name="password"
          label="Senha"
          solo
          required
          :rules="[required]"
          v-model="password"
        />

        <BasePassword
          id="confirmPassword"
          name="confirmPassword"
          label="Confirmação de Senha"
          solo
          required
          :rules="[required, verifyConfirmPassword]"
          v-model="confirmPassword"
        />
      </div>

      <div class="actions">
        <router-link
          class="actions--back-home"
          to="/login"
          id="btn-back"
          name="btn-back"
        >
          Voltar para o ínicio
        </router-link>

        <BaseButton
          id="btn-execute"
          name="btn-execute"
          type="submit"
          color="secondary"
          title="Redefinir a Senha"
          :disabled="!valid"
        />
      </div>
    </v-form>
  </TemplateAccount>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { AccountService } from '@/services/api/account';

export default {
  mixins: [rulesMixin],

  components: {
    TemplateAccount: () => import('@/components/account/template-account')
  },

  data: () => ({
    valid: true,
    password: null,
    confirmPassword: null
  }),

  methods: {
    async handleRecoverPassword() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const { token } = this.$route.params;

        if (token) {
          const accountService = new AccountService();
          const { status } = await accountService.resetPassword({
            token,
            password: this.password,
            confirmPassword: this.confirmPassword
          });

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.$router.push({ name: 'login' });
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    verifyConfirmPassword(v) {
      return v !== this.password ? 'Senhas precisam ser idênticas' : true;
    }
  }
};
</script>
